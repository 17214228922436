/* global Component */
class inputComponent extends Component {

    static name() {
        return "input-component";
    }

    static componentName() {
        return "input-component";
    }

    getProps() {
        return ['def', 'value', 'custom_css_classes'];
    }

    data() {
        return {
            isSet: false,
        };
    }

    mounted() {
        return function () {

        };
    }

    updated() {
        return {

        };
    }

    getComputed() {
        return{
            css_class: function () {

                let default_css = {
                    'col-12': true,
                    'input-field ': (this.editor != 'checkbox' &&  this.editor != 'vuecomponent' && this.editor != 'matrix'),
                }

                return this.custom_css_classes || default_css;

            },
            getDef: function () {
                let defaults = {visibility: true, readonly: false, pastewindow: null, fillpastewindow: null}
                Object.assign(defaults, this.def)
                return defaults;
            },
            label: function () {
                if (this.isSet) {
                    return this.tr(this.getDef.label) + "  (a,b)"
                }
                return this.getDef.label

            },
            editor: function () {
                this.isSet = false;
                switch (this.getDef.editor || this.getDef.type) {
                    case 'string':
                        return 'string';
                    case 'boolean':
                    case 'checkbox':
                        return 'checkbox';
                    case 'combobox':
                        return 'combobox';
                    case 'period':
                        return 'period';
                    case 'date':
                        return 'date';
                    case 'matrix':
                        return 'matrix';
                    case 'datepicker':
                        return 'date';
                    case 'set':
                        this.isSet = true;
                        return "string";
                    case 'vuecomponent':
                        if(this.getDef.component=="PeriodComponent")
                            return "period";
                }
                return "string";
            }
        };
    }

    getMethods() {
        return {
            focus() {
                this.$refs.input.focus()
            },
            fpw: function () {
                return this.getDef.fillpastewindow ? this.getDef.fillpastewindow.bind(this.cardcomponent)() : 'NOHABILITADO'
            }
        };
    }



    getTemplate() {
        return `<div v-bind:class="css_class" ref="div" >
                <label class="active" v-if="editor != 'period' & editor != 'checkbox' " :for="id">{{tr(label)}}</label>        
                <input :disabled="getDef.readonly" :label="label" v-if="editor == 'string'"  type="text" :id="id" placeholder="" :value="value" @input="$emit('input',$event)" ref="input" >
                <input :disabled="getDef.readonly" v-else-if="editor == 'checkbox'" type="checkbox" :id="id" v-bind:checked="value" @change="$emit('input', $refs.input.checked)" ref="input">
                <input :disabled="getDef.readonly" v-else-if="editor == 'date'" type="date" :id="id"  @change="$emit('input', $refs.input.value)" ref="input">
                <material-select  :options="getDef.options" :disabled="getDef.readonly"  v-else-if="editor == 'combobox'" :id="id"  :value="value" @change="$emit('input', $refs.input.value)" ref="input" />
                <period-component :description="getDef" v-else-if="editor == 'period'" class="card-reveal-select" :value="value"  ref="input" @change="$emit('input', $event);" ></period-component>               
        </div>`;
    }
}

inputComponent.registerComponent();
